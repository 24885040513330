<template>
    <div>
        <v-text-field
            v-model="value"
            class="toStakeAmountInput pull-left mt-0 pt-0"
            style="width: 200px"
            v-bind:disabled="loadingData"
            type="number"
            :min="allowZeroValue?0:1"
            :label="label"
            :color="value>maxValue ? 'red' : ''"
            :suffix="suffix"
            hide-spin-buttons
        >
            <v-icon
                slot="prepend"
                @click="decrement()"
                v-bind:disabled="loadingData"
                class="btn-plusminus"
            >
                mdi-minus
            </v-icon>
            <v-icon
                slot="append"
                @click="increment()"
                v-bind:disabled="loadingData"
                class="btn-plusminus"
            >
                mdi-plus
            </v-icon>
        </v-text-field>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            default: null
        },
        initValue: {
            default: 0,
        },
        maxValue: {
            type: Number,
            default: 99999999,
        },
        loadingData: {
            type: Boolean,
            default: false,
        },
        label: {
            default: ""
        },
        allowZeroValue: {
            type: Boolean,
            default: false
        },
        suffix: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            value: 0,
        };
    },
    mounted() {
        this.value = this.initValue;
    },
    computed: {},
    methods: {
        decrement() {
            if (this.allowZeroValue) {
                this.value >= 1 ? this.value-- : null;
            } else {
                this.value > 1 ? this.value-- : null;
            }
        },
        increment() {
            if (this.value < this.maxValue) {
                this.value++
            }
        },
    },
    watch: {
        initValue() {
            this.value = this.initValue;
        },
        value() {
            this.$emit('valueChanged', {value: this.value, id: this.id})
        },
    },
}
</script>

<style lang="scss" scoped>

.card-text {
    font-size: 120%;
}

a {
    text-decoration: underline;
    color: #fff;

}

.btn-plusminus {
    color: #000 !important;
    background: var(--v-golden-base);
    border-radius: 5px;
}

.v-icon {
    color: inherit;
}
</style>
