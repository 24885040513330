var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "page-filters-section",
        { attrs: { "top-img": "/img/card/my-card-header.png" } },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center flex-column" },
            [
              _c("h1", { staticStyle: { "margin-top": "-30px" } }, [
                _vm._v("My card collection"),
              ]),
              _c("span", { staticStyle: { "margin-top": "-10px" } }, [
                _vm._v("Collectible cards in your collection."),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          !_vm.isWalletConnected
            ? _c("v-row", { staticClass: "pt-10" }, [
                _c("div", { staticClass: "col" }, [_c("connect-wallet")], 1),
              ])
            : _vm._e(),
          _vm.isWalletConnected
            ? _c(
                "v-row",
                { staticClass: "pos-relative" },
                [
                  _vm.loading
                    ? _c("v-progress-circular", {
                        staticStyle: { width: "100% !important" },
                        attrs: { size: "300", indeterminate: "" },
                      })
                    : _vm._e(),
                  0 === _vm.cards.length && !_vm.loading
                    ? _c("div", { staticClass: "col" }, [
                        _c("h3", { staticClass: "text-center mt-10" }, [
                          _vm._v("- No Cards -"),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.loading
                    ? _c(
                        "div",
                        { staticClass: "transfer-button" },
                        [
                          _c("v-sheet", {
                            staticStyle: { opacity: "0.1" },
                            attrs: {
                              color: "grey",
                              elevation: "1",
                              height: "100%",
                              width: "100%",
                              rounded: "",
                            },
                          }),
                          _c("card-transfer-popup", {
                            attrs: { nfts: _vm.cards },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.cards, function (item) {
                    return _c(
                      "v-col",
                      {
                        key: item.id,
                        staticClass: "d-flex",
                        attrs: { cols: "6", sm: "auto", "data-id": item.id },
                      },
                      [
                        _c(
                          "v-dialog",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b({}, "div", attrs, false),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                right: "",
                                                "max-width": "400",
                                                "content-class": "mini-tooltip",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mini-card",
                                                          },
                                                          [
                                                            !item.thumbnail
                                                              ? _c(
                                                                  "v-skeleton-loader",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "title",
                                                                          rawName:
                                                                            "v-title",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      type: "card",
                                                                      title:
                                                                        "#" +
                                                                        item.id,
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            item.thumbnail
                                                              ? _c(
                                                                  "div",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "div",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    item.animation_thumbnail_url
                                                                      ? _c(
                                                                          "video",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                autoplay:
                                                                                  "",
                                                                                loop: "",
                                                                                muted:
                                                                                  "",
                                                                                playsinline:
                                                                                  "",
                                                                                src: item.animation_thumbnail_url,
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                muted: true,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: item.thumbnail,
                                                                                    alt: item.name,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    !item.animation_thumbnail_url
                                                                      ? _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: item.thumbnail,
                                                                                alt: item.name,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "name d-flex justify-center",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.name
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              item.wizarre
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltip-content",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "tooltip-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name)
                                                          ),
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          "Collection: " +
                                                            _vm._s(
                                                              item.wizarre
                                                                .collection
                                                            )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        [
                                                          _vm._v("Rarity: "),
                                                          _c("rarity-text", {
                                                            attrs: {
                                                              rarity:
                                                                item.wizarre
                                                                  .rarity,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          "№: " +
                                                            _vm._s(
                                                              item.wizarre
                                                                .noInType
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.isCardPreviewOpen[item.id],
                              callback: function ($$v) {
                                _vm.$set(_vm.isCardPreviewOpen, item.id, $$v)
                              },
                              expression: "isCardPreviewOpen[item.id]",
                            },
                          },
                          [
                            _c("div", { staticClass: "preview-image" }, [
                              item.animation_url
                                ? _c(
                                    "video",
                                    {
                                      attrs: {
                                        autoplay: "",
                                        loop: "",
                                        muted: "",
                                        playsinline: "",
                                        src: item.animation_url,
                                      },
                                      domProps: { muted: true },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.thumbnail,
                                          alt: item.name,
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              !item.animation_url
                                ? _c("img", {
                                    attrs: { src: item.image, alt: item.name },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.cards.length > 0
            ? _c(
                "v-row",
                { staticClass: "text-center mt-5 mb-5" },
                [
                  _c("v-pagination", {
                    staticClass: "mx-auto",
                    attrs: {
                      length: _vm.paginationLength,
                      "total-visible": "10",
                    },
                    on: { input: _vm.onPageChange },
                    model: {
                      value: _vm.page,
                      callback: function ($$v) {
                        _vm.page = $$v
                      },
                      expression: "page",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }