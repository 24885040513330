<template>
    <v-container fluid>
        <page-filters-section top-img="/img/card/my-card-header.png">
            <div class="d-flex align-center justify-center flex-column">
                <h1 style="margin-top:-30px">My card collection</h1>
                <span style="margin-top:-10px">Collectible cards in your collection.</span>
            </div>
        </page-filters-section>

        <div class="page-content">
            <v-row v-if="!isWalletConnected" class="pt-10">
                <div class="col">
                    <connect-wallet></connect-wallet>
                </div>
            </v-row>

            <v-row v-if="isWalletConnected" class="pos-relative">
                <v-progress-circular
                    v-if="loading"
                    size="300"
                    indeterminate
                    style="width: 100% !important;"
                ></v-progress-circular>
                <div v-if="0 === cards.length && !loading" class="col">
                    <h3 class="text-center mt-10">- No Cards -</h3>
                </div>

                <div v-if="!loading"
                     class="transfer-button"
                >
                    <v-sheet
                        color="grey"
                        elevation="1"
                        height="100%"
                        width="100%"
                        rounded
                        style="opacity: 0.1"
                    >
                    </v-sheet>
                    <card-transfer-popup
                        :nfts="cards"
                    >
                    </card-transfer-popup>
                </div>

                <v-col cols="6" sm="auto" v-for="item in cards" :key="item.id" :data-id="item.id" class="d-flex">
                    <v-dialog
                        v-model="isCardPreviewOpen[item.id]"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-tooltip right max-width="400" content-class="mini-tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="mini-card">
                                            <v-skeleton-loader
                                                v-if="!item.thumbnail"
                                                type="card"
                                                :title="'#'+item.id"
                                                v-title
                                            >
                                            </v-skeleton-loader>

                                            <div v-if="item.thumbnail"
                                                 v-bind="attrs"
                                                 v-on="on"
                                            >
                                                <video v-if="item.animation_thumbnail_url" autoplay loop muted
                                                       playsinline
                                                       :src="item.animation_thumbnail_url"
                                                >
                                                    <img :src="item.thumbnail" :alt="item.name"/>
                                                </video>
                                                <img v-if="!item.animation_thumbnail_url" :src="item.thumbnail"
                                                     :alt="item.name"
                                                />
                                                <span class="name d-flex justify-center">
                                                {{ item.name }}
                                            </span>
                                            </div>
                                        </div>
                                    </template>
                                    <span class="tooltip-content" v-if="item.wizarre">
                                    <span class="tooltip-title">{{ item.name }}</span>
                                    <span>Collection: {{ item.wizarre.collection }}</span>
                                    <span>Rarity: <rarity-text :rarity="item.wizarre.rarity"></rarity-text></span>
                                    <span>№: {{ item.wizarre.noInType }}</span>
                                </span>
                                </v-tooltip>
                            </div>
                        </template>

                        <div class="preview-image">
                            <video v-if="item.animation_url" autoplay loop muted playsinline :src="item.animation_url">
                                <img :src="item.thumbnail" :alt="item.name"/>
                            </video>

                            <img v-if="!item.animation_url" :src="item.image" :alt="item.name"/>
                        </div>

                    </v-dialog>
                </v-col>
            </v-row>

            <v-row v-if="cards.length > 0" class="text-center mt-5 mb-5">
                <v-pagination
                    class="mx-auto"
                    v-model="page"
                    :length="paginationLength"
                    total-visible="10"
                    @input="onPageChange"
                ></v-pagination>
            </v-row>
        </div>

    </v-container>
</template>

<script>
import PageFiltersSection from "../components/page-filters-section";
import {apiServiceVue} from "../services/apiService.vue";
import RarityText from "../components/assets/lands/rarity-text";
import MainButton from "../components/ui/main-button";
import CardTransferPopup from "../components/assets/cards/card-transfer-popup";
import {NoWalletError} from "@/errors/WalletErrors";
import ConnectWallet from "@/views/ConnectWallet";

const ITEMS_PER_PAGE = 33;

export default {
    components: {ConnectWallet, CardTransferPopup, MainButton, RarityText, PageFiltersSection},
    data() {
        return {
            page: 1,
            totalItems: 0,
            cards: [],
            loading: true,
            isCardPreviewOpen: [],
        };
    },
    mounted: function () {
        this.init();
        window.addEventListener("reload-view", this.init);
    },
    destroyed() {
        window.removeEventListener("reload-view", this.init);
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        paginationLength() {
            return Math.ceil(this.totalItems / ITEMS_PER_PAGE);
        }
    },
    methods: {
        init() {
            if (this.isWalletConnected) {
                this.onPageChange();
            } else {
                window.addEventListener('user-wallet-loaded', () => {
                    this.onPageChange();
                });
            }
        },
        onPageChange() {
            this.fetchMyCards((this.page - 1) * ITEMS_PER_PAGE, ITEMS_PER_PAGE);
        },
        async fetchMyCards(offset = 0, limit = 100) {
            if (!this.web3.address) {
                throw new NoWalletError();
            }
            this.loading = true;
            this.cards = [];

            const url = process.env.VUE_APP_CARD_NFT_SERVER_URL + 'wallet/' + this.web3.address;
            apiServiceVue.get(url, {
                offset: offset,
                limit: limit,
                withTotal: true,
            })
                .then(res => {
                    this.totalItems = res.data.total;
                    const cards = res.data.items;
                    if (cards && cards.length > 0) {
                        this.cards = cards;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch Cards failed'
                    });
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.page-content {
    margin-top: 200px;
    padding: 0 20px 0 0;
}

.container--fluid {
    margin: 0 50px;
}

.transfer-button {
    width: 150px;
    height: 60px;
    top: -70px;
    left: -5%;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
}

.mini-card {
    width: 180px;
    height: 260px;
    text-align: center;

    .name {
        width: 100%;
        height: 50px;
        overflow: hidden;
    }

    video,
    img {
        margin: auto;
        width: 145px;
        height: 200px;
    }

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
}

.preview-image {
    text-align: center;

    video,
    img {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: 90%;
        max-width: 90%;
        transform: translate(-50%, -50%);
    }
}

@media only screen and (max-width: $lg) {
    .container--fluid {
        margin: 0 20px;
    }
    .page-content {
        padding: 0 10px 0 0;
    }
}

@media only screen and (max-width: $sm) {
    .container--fluid {
        margin: 0;
    }
    .page-content {
        padding: 0;
    }
}
</style>
