var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block w-100" },
    [
      _c(
        "main-button",
        {
          staticClass: "open-popup-btn pos-absolute",
          staticStyle: { width: "120px", top: "10px", left: "15px" },
          attrs: {
            disabled: !_vm.nfts.length,
            "on-click": _vm.openTransferPopup,
          },
        },
        [_vm._v(" Transfer ")]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.transferPopupOpen,
            title: "Item transfer",
            width: "500",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.transferPopupOpen = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _vm.selectedCardObject
                        ? _c("img", {
                            attrs: { src: _vm.selectedCardObject.thumbnail },
                          })
                        : _c("img", { attrs: { src: "/img/card/cards.png" } }),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center align-center",
                      attrs: { cols: "12", sm: "7" },
                    },
                    [
                      _c("br", { staticClass: "d-block d-sm-none" }),
                      _c("br"),
                      _c("v-select", {
                        attrs: {
                          items: _vm.nfts,
                          "item-text": (a) => "#" + a.id + " " + (a.name || ""),
                          "item-value": "id",
                          label: "Choose card",
                        },
                        model: {
                          value: _vm.selectedCardId,
                          callback: function ($$v) {
                            _vm.selectedCardId = $$v
                          },
                          expression: "selectedCardId",
                        },
                      }),
                      _c("br", { staticClass: "d-none d-sm-block" }),
                      _c("br"),
                      _c(
                        "v-form",
                        {
                          model: {
                            value: _vm.isTransferFormValid,
                            callback: function ($$v) {
                              _vm.isTransferFormValid = $$v
                            },
                            expression: "isTransferFormValid",
                          },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "d-inline-block d-sm-block w-100",
                            attrs: {
                              rules: [
                                (v) =>
                                  v.length === 42 ||
                                  "Address has 42 characters",
                              ],
                              counter: "42",
                              label: "Recipient address",
                              color: "#ccc",
                            },
                            model: {
                              value: _vm.transferRecipientAddress,
                              callback: function ($$v) {
                                _vm.transferRecipientAddress = $$v
                              },
                              expression: "transferRecipientAddress",
                            },
                          }),
                          _c(
                            "main-button",
                            {
                              staticClass: "mt-5 pull-right",
                              attrs: {
                                disabled:
                                  !_vm.isWalletConnected ||
                                  !_vm.isTransferFormValid,
                                loading: _vm.transferInProgress,
                                "on-click": () =>
                                  _vm.transferClick(
                                    _vm.transferRecipientAddress,
                                    _vm.selectedCardId
                                  ),
                              },
                            },
                            [_vm._v(" Transfer ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }