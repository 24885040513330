var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-text-field",
        {
          staticClass: "toStakeAmountInput pull-left mt-0 pt-0",
          staticStyle: { width: "200px" },
          attrs: {
            disabled: _vm.loadingData,
            type: "number",
            min: _vm.allowZeroValue ? 0 : 1,
            label: _vm.label,
            color: _vm.value > _vm.maxValue ? "red" : "",
            suffix: _vm.suffix,
            "hide-spin-buttons": "",
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        [
          _c(
            "v-icon",
            {
              staticClass: "btn-plusminus",
              attrs: { slot: "prepend", disabled: _vm.loadingData },
              on: {
                click: function ($event) {
                  return _vm.decrement()
                },
              },
              slot: "prepend",
            },
            [_vm._v(" mdi-minus ")]
          ),
          _c(
            "v-icon",
            {
              staticClass: "btn-plusminus",
              attrs: { slot: "append", disabled: _vm.loadingData },
              on: {
                click: function ($event) {
                  return _vm.increment()
                },
              },
              slot: "append",
            },
            [_vm._v(" mdi-plus ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }