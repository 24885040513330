<template>
    <div class="d-block w-100">
        <main-button
            :disabled="!nfts.length"
            :on-click="openTransferPopup"
            class="open-popup-btn pos-absolute"
            style="width:120px;top:10px;left:15px"
        >
            Transfer
        </main-button>

        <dialog-box
            :model="transferPopupOpen"
            v-on:dialogClosed="transferPopupOpen=false"
            title="Item transfer"
            width="500"
        >
            <div class="mt-5">
                <v-row no-gutters>
                    <v-col cols="12" sm="5">
                        <div class="d-flex">
                            <img
                                v-if="selectedCardObject"
                                :src="selectedCardObject.thumbnail"
                            />
                            <img
                                v-else
                                src="/img/card/cards.png"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" sm="7" class="text-center align-center">

                        <br class="d-block d-sm-none"/><br/>

                        <v-select
                            v-model="selectedCardId"
                            :items="nfts"
                            :item-text="(a)=>'#'+a.id+' '+(a.name||'')"
                            item-value="id"
                            label="Choose card"
                        >
                        </v-select>

                        <br class="d-none d-sm-block"/><br/>

                        <v-form v-model="isTransferFormValid">
                            <v-text-field
                                v-model="transferRecipientAddress"
                                :rules="[v => v.length === 42 || 'Address has 42 characters']"
                                counter="42"
                                label="Recipient address"
                                color="#ccc"
                                class="d-inline-block d-sm-block w-100"
                            ></v-text-field>

                            <main-button
                                :disabled="!isWalletConnected || !isTransferFormValid"
                                :loading="transferInProgress"
                                :on-click="()=>transferClick(transferRecipientAddress, selectedCardId)"
                                class="mt-5 pull-right"
                            >
                                Transfer
                            </main-button>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
        </dialog-box>
    </div>
</template>

<script>

import MainButton from "../../ui/main-button";
import DialogBox from "../../dialog-box";
import MiniEnhancement from "../enhancements/mini-enhancement";
import InputAmount from "../../input-amount";
import CardContract from "../../../services/contracts/cardContract";

export default {
    components: {InputAmount, MiniEnhancement, DialogBox, MainButton},
    props: {
        nfts: Array,
        amount: Number,
    },
    data() {
        return {
            selectedCardId: null,
            transferInProgress: false,
            transferPopupOpen: false,
            selectedAmountToTransfer: 0,
            transferRecipientAddress: '',
            isTransferFormValid: false,
        }
    },
    mounted() {
    },
    computed: {
        selectedCardObject() {
            if (this.selectedCardId) {
                return this.nfts.find(x => x.id === this.selectedCardId);
            }
            return null;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
    },
    methods: {
        transferAmountChanged(obj) {
            this.selectedAmountToTransfer = parseInt(obj.value);
        },
        openTransferPopup() {
            this.transferPopupOpen = true;
        },
        async transferClick(recipient, id) {
            this.transferInProgress = true;
            const enhancementContract = new CardContract(this.web3);
            enhancementContract.transfer(recipient, id)
                .then((res) => {
                    this.flashMessage.show({
                        status: 'success',
                        message: 'Item transfer completed'
                    });
                    const loadedEvent = new CustomEvent('reload-view');
                    window.dispatchEvent(loadedEvent);
                })
                .catch((error) => {
                    console.error(error);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Item transfer failed'
                    });
                })
                .finally(() => {
                    this.transferPopupOpen = false;
                    this.transferInProgress = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.open-popup-btn.main-button {
    width: 30%;
}
</style>
